/* styles.css */

.table-container {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .table th, .table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
/* styles.css */

body {
  --scrollbar-bg: #888;
  --scrollbar-track-bg: #f1f1f1;
}

html[data-theme='dark'] {
  --scrollbar-bg: #555;
  --scrollbar-track-bg: #1e1e1e;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-bg);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-bg);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* styles.css */
.chart-container {
  width: 100%; /* Full width of the parent container */
  height: 400px; /* Set a specific height */
  display: flex; /* Center the chart */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px auto; /* Add margin around the container */
}

.chart-wrapper {
  width: 100%; /* Responsive width */
  height: 100%; /* Full height */
}
